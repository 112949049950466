.left-panel {
  width: 20%;
  min-height: calc(100% - 20px);
  margin-top: 20px;
  box-shadow: 0px 3px 25px rgb(6, 13, 54, 0.15);
  background-color: #efefef;
  position: relative;
  border-radius: 0px 30px 30px 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 1270px) {
    width: 60px;
    height: fit-content;
  }

  .buttons-footer {
    margin-bottom: 10%;
  }

  .profile-container {
    display: flex;
    width: 80%;
    margin: 10% 0 0 10%;

    @media (max-width: 1450px) {
      margin: 10% 0 0 5%;
      width: 90%;
    }

    .profile {
      background-color: #6d7c90;
      border-radius: 30px;
      box-sizing: border-box;
      width: 50px;
      height: 50px;
      aspect-ratio: 1/1;
      padding: 2px;
      margin: 0 8px !important;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      font-size: 20px;

      @media (max-width: 1270px) {
        width: 40px;
        height: 40px;
        border-radius: 20px;
        margin: 0 auto !important;
      }

      & > div {
        font-weight: bold;
      }
    }

    .profile-name {
      align-content: center;

      & > div:first-child {
        font-weight: 600;
      }

      & > div:last-child {
        color: #6d7c90;
        font-family: Lora, sans-serif;
        font-weight: lighter;
      }

      @media (max-width: 1270px) {
        display: none;
      }

      a {
        text-decoration: none;
      }
    }
  }

  .button-navigation {
    margin-top: 3rem;
  }

  .button-container {
    width: 100%;
    margin: 10px 0;

    svg {
      fill: #6d7c90;
      width: 20px;
      height: 20px;
    }

    button {
      width: 80%;
      margin-left: 10%;
    }

    @media (max-width: 1450px) {
      button {
        width: 90%;
        margin-left: 5%;
      }
    }

    @media (max-width: 1270px) {
      button > div {
        .space,
        .label {
          display: none;
        }
      }
    }
  }

  .button-footer-container {
    width: 100%;
    margin: 5px 0;

    svg {
      fill: #6d7c90;
      width: 20px;
      height: 20px;
    }

    button {
      width: 80%;
      margin-left: 10%;
      height: 30px;
      text-transform: initial;
      font-weight: normal;
    }
    @media (max-width: 1450px) {
      button {
        width: 90%;
        margin-left: 5%;
      }
    }

    @media (max-width: 1270px) {
      button > div {
        .space,
        .label {
          display: none;
        }
      }
    }
  }

  .links-tbl {
    color: #6d7c90;
    font-size: 14px;
    text-align: center;
    border-top: 1px solid #637083;
    margin: 1rem 0;
    padding-top: 1rem;
    .social-networks,
    .applications {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      margin-bottom: 1rem;
      svg {
        margin: 0 4px;
        fill: #6d7c90;
        &:hover {
          cursor: pointer;
        }
      }
    }
    @media (max-width: 1270px) {
      p {
        display: none;
      }
    }
  }
}
