* {
  font-family: "Now", sans-serif;
}

body {
  margin: 0;
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
}

#root {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
}

#root > div:not(.header-desktop, .header-mobile, .Toastify) {
  height: calc(100% - 70px);
  width: 100%;
}
