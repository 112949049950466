.progress-bar {
  width: 80%;
  margin: -30px auto 5rem;
  padding: 24px;
  border-radius: 50px;
  box-shadow: 0px 0px 30px #00000029;
  z-index: 1;
  position: relative;
  background-color: white;
  .progress-container {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;

    .progress-text {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      position: relative;
      font-size: 14px;
      span:nth-child(2) {
        color: #e90d2f;
        font-size: 18px;
        font-weight: bold;
      }
    }

    .progress-percentage {
      position: absolute;
      transform: translateX(-50%);
      font-weight: bold;
    }

    .progress-bar-content {
      background: #f0f0f0;
      border-radius: 50px;
      overflow: hidden;
      position: relative;
    }

    .progress-fill {
      background: #e90d2f;
      height: 20px;
      border-radius: 50px;
      position: relative;
      transition: width 0.5s;
    }
    .button-restart {
      display: flex;
      flex-direction: row-reverse;
      button {
        margin-top: 20px;
        border: none;
        color: #6d7c90;
        cursor: pointer;
        background-color: inherit;
        display: flex;
        justify-content: space-between;
        align-items: center;
        p {
          margin: 0;
        }
        svg {
          margin-left: 5px;
          width: 12px;
          height: 12px;
        }
      }
    }
  }
}
