.bellypremium-card {
  width: 25%;
  display: flex;
  box-shadow: rgba(109, 141, 173, 0.3) 4px 10px 50px 0px;
  border-radius: 10px;
  position: relative;
  &:hover {
    cursor: pointer;
  }

  svg {
    position: absolute;
    right: -15px;
    top: -10px;
  }

  &:hover {
    background-color: rgba(250, 181, 184, 0.3);
  }

  .img-div {
    width: 45%;
    img {
      width: 80%;
      margin: 10px;
      border-radius: 5px;
    }
  }

  & > div:last-child {
    width: 55%;
    align-content: center;
    font-size: 18px;
    font-weight: 600;
  }

  @media screen and (max-width: 1000px) {
    width: 100%;
    .img-div {
      img {
        width: 60%;
        margin: 0;
        border-radius: 0;
      }
    }
  }
}
