#lesson {
  position: relative;
  #return-button {
    > button {
      margin-top: 4em;
      margin-bottom: 2em;
      @media screen and (min-width: 1200px) {
        top: 1em;
        left: 1em;
        position: absolute;
      }
    }
  }
  #lesson-container {
    max-width: 90vw;
    min-width: 80vw;
    width: 900px;
    margin: auto;
    padding-top: 5em;
    text-align: center;

    > * {
      max-width: 950px;
      width: 100%;
    }

    #lesson-button {
      margin: 2rem auto 4rem;
      button {
        cursor: pointer;
        border: none;

        border-radius: 10px;
        padding: 1em 2em;
        text-transform: uppercase;
      }
    }

    video {
      background-color: #00000029;
      display: block;
      margin: auto;
    }

    #lesson-unlock {
      width: 100%;
      margin: auto;
      display: block;
      position: relative;

      > img {
        width: 100%;
        display: block;
      }

      #unlock-preview {
        position: absolute;
        top: 0%;
        left: 0%;
        height: 100%;
        width: 100%;
        z-index: -1;
        object-fit: cover;
      }

      #unlock-buttons {
        position: absolute;
        top: 83%;
        width: 100%;
        justify-content: space-around;
        display: flex;

        button {
          cursor: pointer;
          border: none;

          width: 100%;
          @media screen and (max-width: 520px) {
            font-size: 0.5em;
          }

          border-radius: 0px 15px;
          padding: 1em 2em;
          text-transform: uppercase;
        }

        a:last-child button {
          border-radius: 15px 0px;
          background-color: black;
          color: white;
        }
      }
    }

    #lesson-description {
      border-radius: 50px;
      padding: 2em;
      margin-top: 2em;
      box-shadow: 0px 0px 30px #00000029;
      box-sizing: border-box;

      @media screen and (min-width: 700px) {
        display: inline-flex;
        justify-content: space-between;
      }

      > div:first-child {
        text-align: left;

        h1 {
          margin-top: 0;
          text-transform: uppercase;

          > span {
            font-weight: lighter;
          }
        }

        h2 {
          font-size: 12px;
        }
      }

      > div:last-child {
        text-align: right;

        > * {
          display: block;
        }

        button {
          cursor: pointer;
          border: none;

          @media screen and (min-width: 700px) {
            width: 100%;
          }

          border-radius: 0px 15px;
          padding: 1em 2em;
          text-transform: uppercase;
        }

        > a + a > button {
          margin-top: 1em;
          border-radius: 15px 0px;
          background-color: black;
          color: white;
        }
      }
    }
  }
}
