@keyframes growing {
  from {
    transform: scale(0);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0px 10px 20px rgba(43, 0, 18, 0.1);
  text-align: center;
  border-radius: 40px;
  margin-bottom: 1rem;
  padding: 1rem;
  width: 220px;
  animation: 1.5s growing ease-in-out;

  .inactive {
    position: absolute;
    background-color: rgba(128, 128, 128, 0.8);
    border-radius: 20px;
    display: flex;
    place-content: center;
    align-items: center;
    color: white;
    font-weight: 600;
    text-transform: uppercase;
    &:hover {
      cursor: pointer;
    }
  }

  &:hover {
    cursor: pointer;
  }

  img {
    margin-bottom: 1rem;
    border-radius: 20px;
    &:hover {
      cursor: pointer;
    }
  }

  .title {
    text-transform: uppercase;
    font-weight: 600;
  }

  .subtitle {
    color: #827ae8;
    font-style: italic;
    text-transform: capitalize;
  }

  .action {
    color: #e90d2f;
    font-size: 14px;
    padding: 0.5rem 0;
    cursor: pointer;
    font-weight: bold;
  }
}
