#home {
  > section {
    height: 100%;
    display: flex;
    flex-wrap: wrap;

    .main {
      @media screen and (min-width: 1000px) {
        height: 100%;
      }

      overflow: auto;
      width: 60%;

      @media screen and (max-width: 1000px) {
        width: calc(100% - 60px);
      }

      padding: 2rem 1rem;
      box-sizing: border-box;

      #courses-header {
        display: inline-flex;
        justify-content: space-between;
        width: 100%;
        @media screen and (min-width: 1000px) {
          margin-top: 1.5em;
          margin-bottom: 2em;
        }
      }

      h2 {
        padding-left: 2rem;
        font-weight: bold;
        font-size: 1.3rem;
      }

      .more {
        color: #e90d2f;
        font-size: 14px;

        &:hover {
          cursor: pointer;
        }
      }

      & > section > div:first-child {
        justify-content: space-between;
        align-items: center;
      }
    }

    .none-paragraph {
      font-style: italic;
      color: #1c1c1c;
    }
  }

  #courses-list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    & > div {
      margin: 1rem 1.5rem;
    }
  }
}
