#course {
  .container {
    max-width: 1200px;
    padding-top: 2rem;
    margin: auto;
    text-align: center;
  }

  .course-description {
    text-align: center;
    width: 100vw;
    overflow-x: hidden;
    position: relative;
    background-repeat: no-repeat;
    background-size: 100% auto;
    button {
      margin-top: 1em;
      margin-bottom: 2em;
      @media screen and (min-width: 880px) {
        top: 1em;
        left: 1em;
        position: absolute;
      }
    }
    h1 {
      @media screen and (min-width: 880px) {
        padding-top: 5rem;
        padding-bottom: 2rem;
      }
      @media screen and (min-width: 1200px) {
        padding-top: 12rem;
        padding-bottom: 2rem;
      }
      text-transform: uppercase;
      margin-top: 0;
    }
    p {
      margin-bottom: 3rem;
      font-size: 20px;
    }
    svg {
      display: block;
      width: 150%;
      transform: rotate(180deg);
      height: 40px;
      padding-bottom: 10rem;
    }
  }

  .filter-list {
    width: 80%;
    margin: 1rem auto;
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    box-shadow: 0px 3px 15px #00000029;
    font-size: 18px;
    & > div {
      margin: 0.2rem 1.8rem;
      padding: 0.5rem 0.2rem;
      border-bottom: 2px solid transparent;
      &:hover {
        cursor: pointer;
        color: #f0878a;
        border-bottom-color: #f0878a;
      }
    }
    .active {
      color: #f0878a;
      border-bottom: 2px solid #f0878a;
    }
  }

  #search-input {
    text-align: center;
    margin: 2rem 0 0;
    input {
      width: 200px;
      padding: 0.5rem 1rem;
      border-radius: 25px;
      outline: none;
      border: 1px solid grey;
    }
  }
}
