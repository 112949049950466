#login {
  height: 100vh;
  width: 100vw;
  position: relative;
  background: linear-gradient(180deg, #fff2f2 0%, #fbb6b9 100%);

  > #login-modale {
    position: absolute;
    background-color: white;
    top: 50%;
    left: 50%;
    width: 500px;
    max-width: 80%;
    transform: translate(-50%, -50%);
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.07);
    padding: 2rem 3rem;

    > * {
      display: block;
    }

    img {
      margin: auto;
    }

    label {
      margin: 1rem 0;
    }

    input {
      margin-bottom: 1rem;
      padding: 0.5rem;
      border-radius: 0.5rem;
      width: 100%;
      border-color: #ccc;
      border-style: solid;
    }

    button {
      margin: auto;
      display: block;
    }

    a#forgotten-password {
      text-align: center;
      text-decoration: none;
      color: #a4a4a4;
      display: block;
      margin-top: 1em;
    }
    .input-password {
      // display: flex;
      // align-items: center;
      position: relative;
      #toggle-button {
        top: 0;
        right: 0;
        position: absolute;
        background: none;
        border: none;
        cursor: pointer;
        padding: 0.5rem;
        svg {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}
