.widget-bar {
  width: 20%;
  padding: 2rem;
  box-sizing: border-box;

  @media screen and (max-width: 1270px) {
    width: 20%;
    flex: 1;
  }

  @media screen and (max-width: 1000px) {
    width: 100%;
  }

  h2 {
    font-weight: bold;
    font-size: 1.3rem;
    margin-bottom: 2em;
  }

  #streak-block {
    background-color: #efefef;
    padding: 1em;
    box-shadow: 0px 10px 20px #2b001229;
    border-radius: 30px;
    text-align: center;
    width: 100%;
    box-sizing: border-box;
    position: relative;
    &.loading > * {
      filter: blur(4px);
    }

    > p:first-child {
      font-weight: bold;
      font-size: 4em;
      margin-top: 1rem;
      margin-bottom: 1rem;

      img {
        height: 1em;
      }
    }

    > p:last-child {
      color: #6d7c90;
    }
  }

  #achievements {
    position: relative;
    > * {
      filter: blur(4px);
    }
    p {
      margin-top: 2em;
    }

    .list {
      display: inline-flex;
      justify-content: space-around;
      width: 100%;

      .achievement {
        border-radius: 50px;
        height: 50px;
        width: 50px;
        aspect-ratio: 1 / 1;
        background: transparent
          linear-gradient(180deg, #e5fcff 0%, #cecefd 100%) 0% 0% no-repeat;
        margin: auto;
      }
    }
  }

  #good-plans {
    .list {
      a {
        text-decoration: none;
        color: initial;
      }

      div.good-plank-block {
        h3 {
          text-transform: uppercase;
          margin-bottom: 0px;
        }
        p {
          margin-top: 5px;
          color: #6d7c90;
        }

        svg {
          color: #6d7c90;
        }

        box-sizing: border-box;
        padding: 0.5em 2em;
        width: 100%;
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 25px;
        background: #ffffff;
        box-shadow: 0px 10px 20px #2b00121a;
      }
    }
  }

  .no-blur {
    filter: blur(0px) !important;
  }
}
