.accordion {
  width: 100%;
  background-color: white;
  position: absolute;
  top: 70px;
  z-index: 3;
  box-shadow: 0 0 10px 0 #00000029;
  & > div > div > * {
    padding: 0 3%;
  }

  .items-accordion {
    position: relative;
    & > div > div > a > div {
      display: inline-flex;
      align-items: center;
      position: relative;
      margin: 10px 0;
      font-weight: 600;
      font-size: 0.9rem;
      text-transform: uppercase;
      height: 24px;
      svg {
        padding-left: 6px;
      }
    }
    .item-accordion {
      & > div {
        &:hover {
          background-color: rgb(254, 233, 234);
        }
        &:not(:hover) {
          background-color: white;
        }
      }
    }
  }
}
