.upcoming-card {
  width: 40%;
  box-shadow: rgba(109, 141, 173, 0.3) 4px 10px 50px 0px;
  border-radius: 10px;
  position: relative;

  & > div {
    padding: 1rem 5rem;
  }
  svg {
    position: absolute;
    right: -20px;
    top: -20px;
    height: 60px;
    width: 60px;
  }

  &:hover {
    background-color: rgba(250, 181, 184, 0.3);
  }

  &:hover {
    .checkbox {
      background-color: white;
    }
  }

  h4 {
    font-size: 32px;
    b {
      color: rgb(233, 13, 47);
      font-weight: 600;
    }
  }

  .subtitle,
  .description {
    font-size: 20px;
  }

  .description {
    font-style: italic;
    opacity: 0.5;
  }

  button {
    margin: 0.5rem;
    border: 1px solid rgb(233, 13, 47);
  }

  @media screen and (max-width: 1000px) {
    width: 80%;
  }

  @media screen and (max-width: 800px) {
    & > div {
      padding: 1rem 2rem;
    }
    .subtitle,
    .description {
      font-size: 16px;
    }

    h4 {
      font-size: 24px;
    }
  }
}
