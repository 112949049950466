.news-card {
  width: 42%;
  border-radius: 25px;
  box-shadow: rgba(109, 141, 173, 0.3) 4px 10px 50px 0px;
  text-align: center;
  font-weight: 600;
  text-transform: uppercase;
  height: fit-content;
  &:hover {
    cursor: pointer;
  }

  p {
    margin: 12px 0;
  }
  img {
    width: 100%;
  }
  .type-card {
    border-radius: 25px 25px 0 0;
    padding: 6px 0;
    letter-spacing: 2.5px;
    font-size: 18px;
  }
  button {
    padding: 10px 16px;
    height: auto;
    margin-bottom: 1rem;
    letter-spacing: 2px;
  }
  @media screen and (max-width: 800px) {
    width: 45%;
  }
}
