.lesson-card {
  display: inline-flex;
  flex-direction: column;
  text-align: center;
  margin-bottom: 2rem;
  padding: 0 1rem;

  .locked,
  .unlocked {
    position: absolute;
    top: 0;
    left: 0;
    height: calc(100% - 0.5rem);
    width: 100%;
  }

  > .image-section {
    position: relative;
    a {
      > img {
        margin-bottom: 0.5rem;
        max-width: 100%;
        display: block;

        &:hover {
          + .player-section {
            transform: translate(-50%, -50%) scale(1);
            > div {
              background-color: red;
            }
          }
        }

        + .player-section {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%) scale(0);
          transition: all 0.3s ease;

          > div {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #f6abae;
            height: 60px;
            width: 60px;
            position: relative;
            cursor: pointer;
            transition: all 0.3s ease;
            border-radius: 100%;
            font-size: 2em;
          }
          &:hover {
            transform: translate(-50%, -50%) scale(1);
            > div {
              background-color: red;
            }
          }
        }
        &:hover {
          + .player-section {
            transform: translate(-50%, -50%) scale(1);
          }
        }
      }
    }

    + div {
      .title {
        text-transform: uppercase;
        &.red-font {
          color: #e90d2f;
        }
      }

      .subtitle {
        font-style: italic;
      }
    }
  }
}
