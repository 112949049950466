#challenges {
  > section {
    height: 100%;
    display: flex;
    flex-wrap: wrap;

    #main {
      @media screen and (min-width: 1000px) {
        height: 100%;
      }

      overflow: auto;
      width: 60%;
      @media screen and (max-width: 1000px) {
        width: calc(100% - 60px);
      }
      padding: 2rem 1rem;
      box-sizing: border-box;

      #challenge-header {
        display: inline-flex;
        justify-content: space-between;
        width: 100%;
        @media screen and (min-width: 1000px) {
          margin-bottom: 2em;
          margin-top: 1.5em;
        }
      }

      h2 {
        padding-left: 2rem;
        font-weight: bold;
        font-size: 1.3rem;
      }

      .more {
        color: #e90d2f;
        font-size: 14px;
      }

      & > section > div:first-child {
        justify-content: space-between;
        align-items: center;
      }
    }

    .buy-button {
      cursor: pointer;
      border: none;
      color: white;
      background-color: #e90d2f;

      border-radius: 0px 15px;
      padding: 1em 2em;
      text-transform: uppercase;
    }
  }

  #challenge-list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    & > div {
      margin: 1rem 1.5rem;
    }
  }
}
