#course-v2 {
  position: relative;
  #content-course-v2 {
    position: relative;
  }
  #course-v2-button {
    position: absolute;
    top: 0;
    z-index: 1;
    button {
      margin-top: 1em;
      margin-bottom: 2em;
      @media screen and (min-width: 880px) {
        top: 1em;
        left: 1em;
        position: absolute;
      }
    }
  }
  #title-container {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 80vh;
    align-content: center;
    position: relative;
    #bottom-curve {
      position: absolute;
      bottom: -65vh;
      left: -110vw;
      width: 300vw;
      transform: rotate(180deg);
      z-index: 0;
    }
    > h2 {
      font-size: 8rem;
      font-weight: 700;
      text-transform: uppercase;
      color: transparent;
      -webkit-text-stroke: 4px white;
      text-align: center;
      margin: 0;
      @media screen and (max-width: 1200px) {
        font-size: 6rem;
      }
      @media screen and (max-width: 900px) {
        font-size: 4rem;
      }
      @media screen and (max-width: 600px) {
        font-size: 2.5rem;
        margin: 0 1rem;
        -webkit-text-stroke: 2px white;
      }
    }
  }
  .container {
    position: relative;
    > div {
      width: 80%;
      margin-left: 10%;
      h4 {
        font-size: 40px;
        text-align: center;
        color: white;
        line-height: 44px;
        letter-spacing: 9.8px;
        text-transform: uppercase;
        padding: 1.5rem 0;
        border-radius: 50px 0;
        @media screen and (max-width: 1200px) {
          font-size: 32px;
          letter-spacing: 6px;
        }
      }
    }
  }
}
