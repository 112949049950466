.menu {
  position: relative;
  padding: 0 1em;

  & > div,
  & > a {
    display: inline-flex;
    align-items: center;
    position: relative;
    padding: 10px 0;
    font-weight: 600;
    font-size: 0.9rem;
    text-transform: uppercase;
    height: 24px;
    svg {
      margin-left: 6px;
    }
  }

  .submenu {
    position: fixed;
    z-index: 1;
    & > div {
      background-color: white;
      box-shadow: 0 0 10px 0 #00000029;
      text-transform: initial;
    }

    .menu-element {
      font-weight: 600;
      font-size: 0.9rem;
      padding: 8px 16px;

      & > div {
        display: inline-flex;
        align-items: center;
        height: 24px;
      }
      &:hover {
        background-color: rgb(254, 233, 234);
      }
    }
    .submenu2 {
      position: fixed;
      z-index: 1;
      padding-left: 2px;
      & > div {
        background-color: white;
        box-shadow: 0 0 10px 0 #00000029;

        & > div {
          background-color: white;
          height: 24px;
          padding: 8px 16px;
          align-content: center;
          &:hover {
            background-color: rgb(254, 233, 234);
          }
          // & > a > div {
          //   display: inline-flex;
          //   align-items: center;
          // }
        }
      }
    }
  }
}
