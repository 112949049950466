.btn {
  & > div {
    align-items: center;
    display: flex;
  }
  box-sizing: border-box;
  height: 50px;
  padding: 0 16px;
  width: min-content;
  font-size: 14px;
  cursor: pointer;
  font-weight: 600;
  border: none;
  text-transform: uppercase;
  border-radius: 8px;

  .label {
    width: max-content;
  }

  .space {
    width: 10px;
  }

  &:disabled {
    opacity: 0.45;
    cursor: not-allowed;
  }
  &.primary {
    color: white;
    background-color: #e90d2f;
    transition: background-color 0.2s ease;
    svg {
      fill: white !important;
    }
    &:hover {
      background-color: rgb(233, 13, 47, 0.8);
    }
  }

  &.secondary {
    color: #e90d2f;
    background-color: white;
    transition: color 0.2s ease;
    &:hover {
      color: rgb(233, 13, 47, 0.8);
    }
  }

  &.transparent {
    color: #6d7c90;
    background-color: transparent;
    &:hover {
      background-color: rgb(0, 0, 0, 0.1);
    }
  }
}
