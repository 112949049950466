.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  .modal-background {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    position: absolute;
  }
  .modal-content {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    .cross {
      svg {
        cursor: pointer;
        position: absolute;
        width: 20px;
        height: 20px;
        right: -10px;
        top: -30px;
        g {
          fill: white;
        }
      }
    }
    & > div:last-child {
      border-radius: 15px;
      background-color: white;
    }
  }
  .small {
    width: 40%;
    margin-left: 30%;
  }
  .medium {
    width: 60%;
    margin-left: 20%;
  }
  .large {
    width: 80%;
    margin-left: 10%;
  }
}
