#temporary-promotion {
  width: 90%;
  margin: 70px 5% 15px;
  display: flex;
  box-shadow: 0 0 5px 0 #00000029;
  border: 1px solid #00000029;
  background-color: white;
  border-radius: 25px;
  > div {
    width: 85%;
    padding-left: 2%;
    display: flex;
    align-items: center;
    overflow: hidden;
    p {
      display: inline-block;
      white-space: nowrap;
      animation: marquee 18s linear infinite;
    }
  }
  button {
    a {
      text-decoration: none;
      color: inherit;
    }
    width: 15%;
    border-radius: 0 25px 25px 0;
    border: none;
    background-color: rgb(233, 13, 47);
    color: white;
    font-weight: bold;
    font-size: 16px;
    letter-spacing: 0.8px;
  }

  @keyframes marquee {
    0% {
      transform: translateX(200%);
    }
    100% {
      transform: translateX(-100%);
    }
  }

  @media screen and (max-width: 1000px) {
    > div {
      width: 75%;
    }
    button {
      width: 25%;
      font-size: 14px;
    }
    @keyframes marquee {
      0% {
        transform: translateX(100%);
      }
      100% {
        transform: translateX(-100%);
      }
    }
  }

  @media screen and (max-width: 880px) {
    margin: 15px 5%;
  }
}
