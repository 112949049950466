.accordeon-item {
  width: 100%;
  max-width: 1800px;
  margin: 0 auto;
  .accordeon-title {
    display: flex;
    align-items: center;
    &:hover {
      cursor: pointer;
    }
    h4 {
      font-size: 20px;
      letter-spacing: 2px;
      padding: 1.5rem 0 1.5rem 15%;
      margin: 0;
      @media screen and (max-width: 1200px) {
        font-size: 32px;
        letter-spacing: 6px;
      }
    }
    svg {
      margin-left: 0.5rem;
    }
  }
}
