#course-challenge {
  position: relative;
  #content-course-challenge {
    position: relative;
  }
  #course-challenge-button {
    position: absolute;
    top: 0;
    z-index: 1;
    button {
      margin-top: 1em;
      margin-bottom: 2em;
      @media screen and (min-width: 880px) {
        top: 1em;
        left: 1em;
        position: absolute;
      }
    }
  }
  #title-container {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 80vh;
    align-content: center;
    position: relative;
    #bottom-curve {
      position: absolute;
      bottom: -65vh;
      left: -110vw;
      width: 300vw;
      transform: rotate(180deg);
      z-index: 0;
    }
    > h2,
    h1 {
      font-weight: 700;
      text-transform: uppercase;
      color: white;
      text-align: center;
      margin: 0;
    }
    h2 {
      font-size: 4rem;
      @media screen and (max-width: 1200px) {
        font-size: 3rem;
      }
      @media screen and (max-width: 900px) {
        font-size: 2rem;
      }
      @media screen and (max-width: 600px) {
        font-size: 1.5rem;
        margin: 0 1rem;
      }
    }
    h1 {
      font-size: 5rem;
      @media screen and (max-width: 900px) {
        font-size: 4rem;
      }
      @media screen and (max-width: 600px) {
        font-size: 2.5rem;
        margin: 0 1rem;
      }
    }
  }
  .container {
    position: relative;
  }
}
