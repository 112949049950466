.lesson-card-v2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 0 20px 0 rgba(34, 3, 3, 0.06);
  margin: 1rem auto;
  border-radius: 25px;
  max-width: 1200px;
  &:hover {
    cursor: pointer;
  }

  & > div:first-child {
    display: flex;
    width: 90%;
  }
  .player-section {
    width: 10%;
    > div {
      height: 40px;
      width: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100%;
      border: 6px solid black;
      svg {
        height: 1.5rem;
      }
    }
  }
  .lesson-img {
    width: 20%;
    img {
      width: 80%;
      border-radius: 25px 0 0 25px;
    }
  }
  .title-div {
    width: 75%;
    margin-right: 5%;
    align-content: center;
    font-size: 17px;
    letter-spacing: 0.5px;
  }
  @media screen and (max-width: 1200px) {
    .lesson-img {
      width: 50%;
    }
  }
  @media screen and (max-width: 800px) {
    .player-section {
      > div {
        height: 30px;
        width: 30px;
        border: 4px solid black;
        svg {
          height: 1rem;
        }
      }
    }
  }
}
