#belly-premium {
  overflow-x: hidden;
  scroll-behavior: smooth;
  & > section {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    #return-button {
      order: 1;
      > button {
        margin-top: 1em;
        margin-bottom: 2em;
        @media screen and (min-width: 880px) {
          top: 1em;
          left: 1em;
          position: absolute;
        }
      }
    }
    & > div:first-child {
      display: inline-flex;

      #title {
        h2 {
          text-transform: uppercase;
          font-size: 40px;
          font-weight: 500;
          &:nth-child(1) {
            margin-bottom: 0.2rem;
          }
          &:nth-child(2) {
            margin-top: 0;
          }
        }
        #bp {
          font-weight: 700;
          color: rgb(233, 13, 47);
        }
        width: 50%;
        margin: 10% 0 10% 10%;

        @media screen and (max-width: 1280px) {
          margin: 10% 0 10% 5%;
        }

        button {
          a {
            text-decoration: none;
            color: white;
          }
          margin-top: 5%;
          height: auto;
          padding: 8px 32px;
          border-radius: 25px;
          background-image: linear-gradient(
            rgb(255, 157, 172) 0%,
            rgb(233, 13, 47) 100%
          );
          letter-spacing: 0.4px;
          font-size: 18px;
          &:hover {
            cursor: pointer;
          }
        }
        p {
          font-style: italic;
          line-height: 25px;
          width: 80%;
          letter-spacing: 0.4px;
        }
      }

      #imgs {
        width: 40%;
        position: relative;
        #bellypremium-img {
          width: 90%;
          margin: 5% 0 0 -30%;
        }
        #tache-rose {
          position: absolute;
          width: 100%;
          right: -200px;
          top: 20px;
          z-index: -1;
        }
        #papillon1-img {
          position: absolute;
          width: 30px;
          top: 3%;
          left: -10%;
          z-index: 0;
        }
        #papillon2-img {
          position: absolute;
          width: 30px;
          bottom: 35%;
          left: -40%;
          z-index: 0;
        }
        #papillon3-img {
          position: absolute;
          width: 80px;
          top: 8%;
          right: 40%;
          z-index: 0;
        }

        @media screen and (max-width: 1280px) {
          #papillon1-img {
            top: 10%;
          }
          #bellypremium-img {
            margin: 15% 0 0 -15%;
          }
        }
      }
      @media screen and (max-width: 1000px) {
        flex-direction: column-reverse;
        #imgs {
          width: 100%;
          #bellypremium-img {
            margin: 10% 30% 0 30%;
            width: 40%;
          }
          #papillon1-img {
            left: 35%;
          }
          #papillon3-img {
            right: 30%;
          }
          #papillon2-img {
            left: 20%;
          }
        }
        #title {
          margin: 0 10%;
          width: 80%;
          text-align: center;
          p {
            margin-left: 10%;
          }
          button {
            margin-top: 3%;
          }
        }
      }
    }
  }
  .bp-data {
    margin: 2rem 10%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    & > div {
      margin: 18px;
      background-color: white;
    }
    @media screen and (max-width: 1280px) {
      margin: 5% 0 0;
    }
    @media screen and (max-width: 1000px) {
      display: block;
      width: 80%;
      margin: auto;
    }
  }
  #news-month {
    width: 80%;
    font-size: 20px;
    display: flex;
    margin: 5rem 10% 2rem;

    & > div:first-child {
      width: 50%;
      align-content: center;
      & > p:first-child {
        font-size: 32px;
        b {
          color: rgb(233, 13, 47);
          font-weight: 600;
        }
      }
      & > p:nth-child(2) {
        margin-bottom: 3rem;
        font-style: italic;
        opacity: 0.4;
      }
    }
    .button-list {
      display: flex;
      button {
        height: auto;
        padding: 8px 32px;
        border-radius: 25px;
        font-size: 18px;
        background-color: #8ed1fc;
        color: white;
      }
      & > div:first-child > button {
        background-image: linear-gradient(
          rgb(255, 157, 172) 0%,
          rgb(233, 13, 47) 100%
        );
      }
      & > div:nth-child(2) > button {
        margin: 0 18px;
      }
      @media screen and (max-width: 1500px) {
        display: block;
        & > div:nth-child(2) > button {
          margin: 18px 0;
        }
      }
      @media screen and (max-width: 1000px) {
        display: flex;
        & > div:nth-child(2) > button {
          margin: 0 18px;
        }
        button {
          margin-bottom: 2rem;
        }
      }
      @media screen and (max-width: 800px) {
        display: block;
        & > div:nth-child(2) > button {
          margin: 6px 0 18px 0;
        }
      }
    }
    .lesson-list {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: 45%;
      margin-left: 5%;
      justify-content: space-between;
      & > div {
        margin: 1rem 0;
      }
    }
    @media screen and (max-width: 1000px) {
      display: block;
      & > div {
        width: 80% !important;
        margin-left: 10% !important;
        text-align: center;
      }
    }
    @media screen and (max-width: 800px) {
      display: block;
      & > div:nth-child(2) {
        width: 100% !important;
        margin-left: 0% !important;
      }
    }
  }
  .upcoming-content {
    width: 100%;
    text-align: center;
    margin-top: 5rem;

    & > div:first-child {
      & > p:first-child {
        font-size: 32px;
      }
      & > p:nth-child(2) {
        font-style: italic;
        opacity: 0.4;
        font-size: 20px;
      }
    }
    .upcoming-cards {
      display: flex;
      justify-content: center;
      & > div {
        margin: 1rem;
      }

      .checkbox {
        margin: 8px 0;
        padding: 12px 8px;
        text-align: initial;
        border: 1px solid rgb(255, 157, 172);
        position: relative;
        display: inline-block;
        vertical-align: middle;
        width: 100%;

        label {
          display: inline-block;
          position: relative;
          padding-left: 25px;
          width: calc(100% - 25px);
          cursor: pointer;
        }

        label:before {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: 15px;
          height: 15px;
          border: 2px solid rgb(255, 157, 172);
          background-color: transparent;
        }

        input[type="checkbox"] {
          position: absolute;
          opacity: 0;
          width: 0;
          height: 0;

          &:disabled + label {
            cursor: not-allowed;
          }
        }

        input[type="checkbox"]:checked + label:before {
          background-color: rgba(255, 157, 172, 0.6);
        }

        .percent-suffix {
          font-size: 0.6em;
          font-style: italic;
          vertical-align: middle;
        }
      }

      @media screen and (max-width: 1000px) {
        display: block;
        & > div {
          margin: 2rem 0 2rem 10%;
        }
      }
    }
  }
  .highlight {
    display: flex;
    width: 80%;
    margin: 5% 0 0% 10%;
    padding-bottom: 5%;
    align-items: center;
    & > div {
      width: 50%;
    }
    p {
      font-size: 32px;
      b {
        color: rgb(233, 13, 47);
        font-weight: 600;
      }
    }
    video {
      width: 100%;
    }
    @media screen and (max-width: 1000px) {
      display: block;
      & > div {
        width: 80%;
        margin-left: 10%;
      }
    }
  }

  .modal-content {
    img {
      width: 100%;
    }
  }
}
