.header-desktop {
  width: 100%;
  height: 70px;
  box-shadow: 0px 3px 15px #00000029;
  position: relative;
  z-index: 10;
  background-color: white;
  & > div {
    display: flex;
    justify-content: center;
    position: relative;
    height: inherit;
  }

  .header-menu {
    display: inline-flex;
    align-items: flex-end;
    margin-bottom: 2px;
  }

  #logo-header {
    width: 10em;
    position: relative;
    img {
      position: absolute;
      width: 5em;
      background-color: white;
      border-radius: 150px;
      padding: 1em;
      aspect-ratio: 1;
      margin: 1em 1.5em;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.17);
    }
  }
}

.header-mobile {
  width: 100vw;
  height: 70px;
  background-color: white;
  z-index: 2;
  & > div:first-child {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: inherit;
    padding: 0 3%;
    box-shadow: 0px 3px 15px #00000029;
  }

  #logo-header {
    img {
      width: 25%;
    }
  }

  .list-svg {
    display: flex;
    align-items: center;
    & > div {
      padding: 0 10px;
      &:hover {
        cursor: pointer;
      }
    }
  }
}
